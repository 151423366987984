.blog-item-wrapper {
    height: 100%; /* Ensure all items take up the same height */
  }
  
  .blog-item {
    height: 100%; /* Ensure all items take up the same height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .blog-img img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .blog-title {
    margin: 10px;
  }
  
  .blog-meta {
    color: #777;
    margin: 0 10px 10px;
  }
  
  .blog-text {
    line-height: 1.6;
    margin: 0 10px 10px;
  }
  