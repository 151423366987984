.service-item-wrapper {
    height: 100%; /* Ensure all items take up the same height */
  }
  
  .service-item {
    height: 100%; /* Ensure all items take up the same height */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .service-img img {
    width: 100%;
    height: auto;
  }
  
  .service-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .service-img:hover .service-overlay {
    opacity: 1;
  }
  
  .service-overlay p {
    margin: 0;
    text-align: center;
    padding: 10px;
  }
  
  .service-text {
    text-align: center;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .service-item-wrapper {
      height: auto; /* Reset height for smaller screens */
    }
  
    .service-item {
      height: auto; /* Reset height for smaller screens */
    }
  }
  