.single-blog-container {
    margin: 0 auto;
    padding: 1rem 10rem;
    background-color: #fff;
}

.blog-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.blog-img img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
}

.blog-details {
    padding: 1rem;
}

.blog-title {
    margin-bottom: 10px;
}

.blog-meta {
    color: #777;
    margin-bottom: 10px;
}

.blog-text {
    line-height: 1.6;
}

.back-button {
    padding: 1rem 1rem;
    background-color: #000;
    color: #fdbe33;
    font-weight: 600;
    border: 2px solid #fdbe33;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    padding: 1rem 1rem;
    background-color: #fdbe33;
    color: #000;
    font-weight: 600;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .single-blog-container {
        padding: 1rem;
    }
  
    .blog-details {
        padding: 1rem;
    }
}
